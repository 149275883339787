import React, {createContext, lazy, Suspense} from 'react';

// @ts-ignore
import {BooksHooks, ModalSpinner, NotFound} from '@paytheory/pay-theory-ui';

import { ErrorMessage, SuccessMessage } from '@paytheory/components.common.portal_head';

import {generateMenu} from './views/util';

import './App.css';

import {Navigate, Route, Routes} from 'react-router-dom';

import {withAuthenticator} from '@aws-amplify/ui-react';

import * as Login from './components/LoginComponents';

// @ts-ignore
import {CognitoUserAmplify} from "aws-amplify";

import * as ROUTES from './constants/routes';
import {useUserTimeout} from "./hooks";

const Font = lazy(() => //@ts-ignore
    import ('@paytheory/pay-theory-ui/dist/Font'));

// const Unauthorized = lazy(() =>
//   import ('@paytheory/pay-theory-ui'))

// const NotFound = lazy(() =>
//   import ('@paytheory/pay-theory-ui'))

const ManageAdmins = lazy(() =>
    import ('./views/ManageAdmins'));

const ManageMerchants = lazy(() =>
    import ('./views/ManageMerchants'));

const MerchantDetail = lazy(() =>
    import ('./views/MerchantDetail'));

const Settings = lazy(() =>
    import ('./views/Settings'));

export const AppContext = createContext({});

type AppProps = {
    signOut: () => void;
    user: CognitoUserAmplify;
}

export const App = ({user, signOut}: AppProps) => {
    const typekit = process.env.REACT_APP_TYPEKIT;
    // Set up a timeout to sign out the user if they are inactive for a set amount of time
    useUserTimeout(user, signOut)

    return (
        <div id="container">
            <div className="spinner-wrapper">
                <div className="modal-wrapper">
                    <BooksHooks.Context.Menu.Provider value={generateMenu()}>
                        <AppContext.Provider
                            value={{SuccessMessage, ErrorMessage, signOut}}>
                            <Suspense fallback={<ModalSpinner on/>}>
                                <Routes>
                                    <Route path="/" element={<Navigate replace to={ROUTES.HOME}/>}/>
                                    <Route
                                        path={ROUTES.MANAGE_ADMINS}
                                        element={<ManageAdmins/>}
                                    />
                                    <Route
                                        path={ROUTES.MANAGE_MERCHANTS}
                                        element={<ManageMerchants/>}
                                    />
                                    <Route
                                        path={ROUTES.MERCHANT_DETAIL}
                                        element={<MerchantDetail/>}
                                    />
                                    <Route
                                        path={ROUTES.SETTINGS}
                                        element={<Settings/>}
                                    />
                                    <Route
                                        path="*"
                                        element={<NotFound/>}
                                    />
                                </Routes>
                                <Font typekit={typekit}/>
                            </Suspense>
                            <ModalSpinner/>
                        </AppContext.Provider>
                    </BooksHooks.Context.Menu.Provider>
                </div>
            </div>
        </div>
    );
};

export default withAuthenticator(App, {
    loginMechanisms: ['email'],
    components: {
        Header: Login.Header,
        SignIn: {
            Header: Login.SignInHeader
        },
    }
});
